
import { Component, Vue } from 'vue-property-decorator'
import CarTypeDialog from './CarTypeDialog.vue'
import { CarBaseInfo } from '@/types/carManage'

@Component({
  name: 'CarManageBaseList',
  components: { CarTypeDialog }
})
export default class extends Vue {
  private info = {
    projectName: '',
    machineName: '',
    deviceNumber: '',
    typeId: ''
  }

  private page = 1
  private size = 10
  private total = 0

   private loading = false
  private tableData: CarBaseInfo[] = []

  private carTypeShow = false

  private currentRow: CarBaseInfo | null = null
  private showBindDevice = false
  private locationDeviceList =[]
  private loadingDlg = false

  created () {
    this.getList()
  }

  get carTypeList () {
    return this.$store.state.carTypeList
  }

  onSearch () {
    this.page = 1
    this.getList()
  }

  getList () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: CarBaseInfo[] }>(
        this.$apis.carManage.selectMachineByPage,
        {
          ...this.info,
          page: this.page,
          size: this.size
        }
      )
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 新增车辆
  onAdd (id: string) {
    this.$router.push({ name: 'addBase' })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'editBase',
      params: {
        id
      }
    })
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'detailBase',
      params: {
        id
      }
    })
  }

  // 删除
  onDelete (machineId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.carManage.deleteDataMachine, { machineId }).then(() => {
          this.$message.success('删除成功')
          this.getList()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 绑定设备
  bindDevice (row: CarBaseInfo) {
    this.currentRow = row
    this.$axios.get(this.$apis.location.locationBotBindMachineList, { projectId: row.projectId, machineId: row.machineId }).then((res) => {
      this.locationDeviceList = res || []
      this.showBindDevice = true
    })
  }

  // 确定绑定设备
  onBind () {
    if (this.currentRow && !this.currentRow.deviceId) {
      this.$message.warning('设备串号不能为空')
      return
    }
    if (this.currentRow) {
      this.loadingDlg = true
      this.$axios.post(this.$apis.carManage.bindDevice, { machineId: this.currentRow.machineId, deviceId: this.currentRow.deviceId }).then(() => {
        this.$message.success('绑定成功')
        this.showBindDevice = false
        this.getList()
      }).finally(() => {
        this.loadingDlg = false
      })
    }
  }

  // 解绑设备
  unBindDevice (row: CarBaseInfo) {
    this.$confirm('确定解除绑定车载终端吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.carManage.unbindDevice, { machineId: row.machineId }).then(() => {
          this.$message.success('解绑成功')
          this.showBindDevice = false
          this.getList()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 关闭
  handleCloseBind () {
    this.currentRow = null
    this.showBindDevice = false
  }

  // 车辆类型配置
  carTypeConfig () {
    this.carTypeShow = true
  }

  closeDialog () {
    this.carTypeShow = false
  }
}
