
import { Component, Vue, Prop } from 'vue-property-decorator'
import { CarTypeItem } from '@/types/carManage'

@Component({
  name: 'CarTypeDialog'
})
export default class extends Vue {
  @Prop() readonly carTypeShow!: boolean

  private loading = false
  private tableData: CarTypeItem[] = []

  private oldData: { [key: number]: CarTypeItem } = {}
  private isAddCarType: number | null = null

  created () {
    this.getCarTypeList()
  }

  getCarTypeList () {
    this.loading = true
    this.$axios.get(this.$apis.carManage.selectVehicleTypeList).then(res => {
      if (res) {
        this.tableData = res || []
        this.$store.commit('setCarTypeList', res)
      }
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.isAddCarType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData.push({
        name: '',
        speedLimit: '',
        stopLimit: '',
        isInput: true
      })
      this.isAddCarType = this.tableData.length - 1
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onEdit (row: CarTypeItem, index: number) {
    if (this.isAddCarType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData[index] = {
        ...row,
        isInput: true
      }
      this.oldData[index] = row
      this.isAddCarType = index
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
    }
  }

  onDelete (typeId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.carManage.deleteDataVehicleType, { typeId }).then(() => {
          this.$message.success('删除成功')
          this.isAddCarType = null
          this.getCarTypeList()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onSave (row: CarTypeItem) {
    if (row.name && row.speedLimit && row.stopLimit) {
      const info = row.typeId
        ? {
          typeId: row.typeId,
          name: row.name,
          speedLimit: row.speedLimit,
          stopLimit: row.stopLimit
        }
        : {
          name: row.name,
          speedLimit: row.speedLimit,
          stopLimit: row.stopLimit
        }

      const url = row.typeId
        ? this.$apis.carManage.updateVehicleType
        : this.$apis.carManage.insertVehicleType

      this.$axios.post(url, info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.isAddCarType = null
        this.getCarTypeList()
      })
    } else {
      this.$message({
        message: '请输入车辆完整数据',
        type: 'warning'
      })
    }
  }

  onCancel (row: CarTypeItem, index: number) {
    this.isAddCarType = null
    if (row.typeId) {
      // 是否为编辑
      this.tableData[index].isInput = false
      this.tableData[index].name = this.oldData[index].name // 取消值不变
      this.tableData[index].speedLimit = this.oldData[index].speedLimit // 取消值不变
      this.tableData[index].stopLimit = this.oldData[index].stopLimit // 取消值不变
    } else {
      this.tableData.splice(index, 1)
    }
  }

  closeDialog () {
    this.$emit('closeDialog')
  }
}
